<template>
    <div class="app-video-popup">
        <video
            :src="file"
            controls
            class="video-with-subtitles"
            :controlsList="checkSecured"
            crossorigin="use-credentials"
            @contextmenu="checkClick($event)"
        >
            <track
                v-for="subtitle in options.video.subtitles"
                :key="subtitle.id"
                kind="subtitles"
                :src="generateUrl(subtitle.file)"
                :srclang="subtitle.lang"
                :label="getSubtitleTile(subtitle.lang)"
                :default="subtitle.default || false"
            />
        </video>
    </div>
</template>

<script>
import languages from '@/assets/js/languages';
import { generateFileUrl, generateUrlSubtitle } from '@/utils/http-helper';

export default {
    name: 'index',

    computed: {
        options() {
            return this.$store.getters.popupOptions;
        },

        file() {
            return this.options.video.noPathGenerate ? this.options.video.file : generateFileUrl(this.options.video.file);
        },

        checkSecured() {
            if (this.options.video.secured) {
                return 'nodownload';
            }
            return '';
        },
    },

    methods: {
        generateUrl(file) {
            return generateUrlSubtitle(file);
        },
        getSubtitleTile(lang) {
            return languages.find((item) => item.value === lang).label;
        },
        checkClick(e) {
            if (this.options.video.secured) {
                e.preventDefault();
            }
        },
    },
};
</script>

<style scoped lang="scss">
.app-video-popup {
    max-height: calc(80vh - 6rem - 14px);

    video {
        width: 100%;
        max-height: calc(80vh - 7rem - 14px);
        margin-top: 2rem;
    }
}

.video-with-subtitles::cue {
    background: rgba(255, 255, 255, 0.75); /* Полупрозрачная белая подложка */
    color: black; /* Черный цвет текста */
    padding: 2px 4px; /* Отступы вокруг текста */
    border-radius: 4px; /* Сглаженные углы */
}
</style>
