<template>
    <div class="disposer-app-sequence">
        <div class="disposer-app-topic-title">
            Questions
        </div>
        <div class="questions-table">
            <div class="row-tr row-tr-head">
                <div class="number">
                    Number
                </div>

                <div class="subtitle">
                    Subtitle
                </div>

                <div class="intro-text">
                    Introductory text
                </div>

                <div class="question">
                    Question
                </div>

                <div
                    v-if="activePollingQuestions"
                    class="add-polling"
                >
                    Open polling question
                </div>

                <div class="private-comment">
                    Private Comment
                </div>

                <div class="hide-comment">
                    Hide Comment
                </div>

                <div class="edit-delete">
                    Save
                </div>
            </div>

            <question-row
                v-for="question in availableQuestions"
                :key="question.id"
                :question.sync="question"
                :topic-id="topicId"
                :event-id="eventId"
                :active-polling-questions="activePollingQuestions"
                @deleteQuestion="deleteQuestion"
                @getEditedTopic="getEditedTopic"
            />
        </div>
        <app-button @click="addQuestion">
            Add question
        </app-button>
    </div>
</template>

<script>
    import appButton from '@/components/disposer/app/app-button';
    import questionRow from '@/components/disposer/pages/topic/question-row';

    export default {
        name: 'disposer-app-sequnce',

        components: {
            appButton,
            questionRow,
        },

        props: {
            eventId: {
                type: Number,
                default: null,
            },
            topicId: {
                type: Number,
                default: null,
            },
            availableQuestions: {
                type: Array,
                default: () => [],
            },
        },

        data: () => ({
            editedQuestion: {
              id: Math.random(),
              number: 1,
              subtitle: '',
              intro: '',
              name: '',

              surveys: [],
            },
        }),


        computed: {
            platform() {
              return this.$store.getters['platform/platform'];
            },
            activePollingQuestions() {
              return this.platform.settings.survey;
            },
        },

        methods: {
            addQuestion() {
                const availableQuestions = [...this.availableQuestions];
                availableQuestions.push({
                    id: Math.random(),
                    editDefault: true,
                    surveys: [],
                });
                this.$emit('update:availableQuestions', availableQuestions);
            },
            deleteQuestion(id) {
                const questions = this.availableQuestions.filter(q => q.id !== id);
                this.$emit('update:availableQuestions', questions);
            },
            getEditedTopic() {
              this.$emit('getEditedTopic');
            },
        },
    };
</script>

<style scoped lang="scss">
.disposer-app-sequence {
    padding: 20px 0;

    .questions-table {
        position: relative;
        margin-bottom: 30px;
        background-color: white;

        .disposer-app-input {
            margin-top: 0;
        }

        .row-tr.row-tr-head {
            position: sticky;
            top: 0;
            left: 0;
            z-index: 1;
            background-color: #d0d1d2;
        }

        .question-row {
            border-bottom: 1px solid transparent;
        }

        .row-tr {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 10px;
            background-color: white;
            border: 1px solid #d0d1d2;

            .number,
            .subtitle,
            .intro-text,
            .question,
            .add-polling,
            .private-comment,
            .hide-comment,
            .edit-delete {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                text-align: center;
                flex: 1;

                &.number {
                    flex: 0.5;
                }

                &.subtitle,
                &.intro-text,
                &.question {
                    flex: 2;
                }

                &.add-polling,
                &.private-comment,
                &.hide-comment,
                &.edit-delete {
                    flex: 1;
                }
            }

            .disposer-app-checkbox {
                display: flex;
                align-items: center;
                justify-content: center;

                .box {
                    width: 16px;
                    height: 16px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    background-color: white;
                    cursor: pointer;
                }
            }

            .edit-delete-align {
                display: flex;
                justify-content: flex-end;
                padding-right: 10px;
            }

            .app-actions {
                display: flex;
                justify-content: space-between;

                img {
                    cursor: pointer;
                    margin: 0 5px;

                    &:hover {
                        opacity: 0.7;
                    }
                }
            }
        }
    }
}

</style>
