import { post } from '@/utils/http-helper';

const CLICK = '/analytic/click';

export const sendClick = (partId, typeId, fileId, topicId=null) => {
    const obj = {
        topic_id: topicId,
        part_id: partId,
        type_id: typeId,
        resource_id: fileId,
    };
    post(CLICK, obj);
};
