import { get } from '@/utils/http-helper';

const GET_TOPICS = '/topic/index?size=10';
const GET_TOPICS_SHORT = '/topic/index-short';
const TOPIC_VIEW = '/topic/view';
const TOPIC_VIEW_PARTICIPANTS = '/topic/view-participants';
const GET_PARTICIPANTS = '/participant/index-short';
const GET_SECTIONS = 'topic/index-section';

export default {
    namespaced: true,
    state: {
        topicsInfo: null,
        topics: null,
        topicsShort: null,
        topic: null,
        participants: {},
        page: 1,
        sections: [],
    },
    actions: {
        async get({ commit, state }, { id, page, size = 0 }) {
            let newPage = page;
            if (!page) {
                newPage = state.page;
            }
            const topics = await get(GET_TOPICS, { event_id: id, page: newPage, size });
            if (topics.status) {
                commit('updateTopics', { topics: topics.data, page });
            }
        },
        async getTopic({ commit }, { topicId, eventId, size }) {
            const payload = {
                event_id: eventId,
                id: topicId,
                size: size,
            };
            const topic = await get(TOPIC_VIEW, payload);
            const topicParticipants = await get(TOPIC_VIEW_PARTICIPANTS, payload);
            if (topic.status) {
                commit('updateTopic', topic.data);
                commit('updateParticipants', topicParticipants.data);
            }
        },
        async getSections({ commit }, { eventId, type }) {
            const sections = await get(GET_SECTIONS, { event_id: eventId, type: type });
            commit('updateSections', sections.data);
        },
        async getTopicsShort({ commit }, { id, type, type_discussion }) {
            const topics = await get(GET_TOPICS_SHORT, { event_id: id, type, type_discussion });
            if (topics.status) {
                commit('updateTopicsShort', topics.data);
            }
        },
        async clearEditedTopic({ commit }) {
            commit('updateTopic', null);
        },
        async getParticipants({ commit }, { id, role, size }) {
            const payload = {
                event_id: id,
                role: role,
                size: size,
            };
            const participants = await get(GET_PARTICIPANTS, payload);
            if (participants.status) {
                commit('updateParticipants', participants.data);
            }
            return participants;
        },
    },
    mutations: {
        updateTopics(state, { topics, page }) {
            state.topicsInfo = topics;
            state.topics = topics.topics;
            if (page) {
                state.page = page;
            }
        },
        updateTopicsShort(state, topics) {
            state.topicsShort = topics.topics;
        },
        updateTopic(state, topic) {
            state.topic = topic;
        },
        updateParticipants(state, participants) {
            state.participants = participants.participants;
        },
        updateSections(state, sections) {
            state.sections = sections.sections;
        },
    },
    getters: {
        get: state => state.topics,
        getTopicsInfo: state => state.topicsInfo,
        getParticipants: state => state.participants,
        getExternalParticipants: state => state.participants.external,
        getTopic: state => state.topic,
        getTopicsShort: state => state.topicsShort,
        getPage: state => state.page,
        getSections: state => state.sections,
    },
};
